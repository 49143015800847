import "react-international-phone/style.css";
import styled from "styled-components";
import colors from "../../theme/colors";

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { useState } from "react";

const HelperText = styled.div`
  color: ${colors.redError};
  font-size: 10px;
`;

const PhoneNumberFormat = require("google-libphonenumber").PhoneNumberFormat;
// Get an instance of `PhoneNumberUtil`.
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

const FormatPhoneNumber = (value) => {
  if (!value) return;
  const number = phoneUtil.parseAndKeepRawInput(value);
  return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
};

const InternationalPhoneInput = ({
  value,
  onChange,
  isMobile,
  ...restProps
}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        if (firstLoad) {
          setFirstLoad(false);
        } else {
          onChange(data);
        }
      },
    });

  return (
    <>
      <TextField
        style={{ zIndex: "99" }}
        variant="outlined"
        label="Phone Number"
        color="primary"
        placeholder="Phone Number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        type="tel"
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginRight: "2px", marginLeft: "-8px" }}
            >
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  // Remove default outline (display only on focus)
                  fieldset: {
                    display: "none",
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: "block",
                    },
                  },
                  // Update default spacing
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{ display: "flex" }} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{ marginRight: "8px" }}
                      />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />
      {isMobile && (
        <HelperText>
          Please Note: The phone number must be able to receive text messages
          for alarm notifications. Please do not enter a landline phone number.
        </HelperText>
      )}
    </>
  );
};

const isPhoneNumberValid = (value) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
  } catch (error) {
    return false;
  }
};

export { InternationalPhoneInput, FormatPhoneNumber, isPhoneNumberValid };
