import styled from "styled-components";
import colors from "../../../theme/colors";

const FormContainer = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FullPageFormWrapper = styled.div`
  margin: 0 0 0 20px;
  @media (max-width: 700px) {
    margin: 0 0 0 0;
  }
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 650px;
`;

const FormSubmitError = styled.div`
  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${colors.redError};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;
const HeaderNote = styled.div`
  font-size: 10px;
  font-style: italic;
  margin-bottom: 10px;
`;

export {
  FullPageFormWrapper,
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  HeaderNote,
  InputFieldsWrapper,
};
