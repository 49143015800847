import styled from "styled-components";
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { Button, Card, Skeleton } from "@mui/material";
import { formatDateTimeStamp } from "../../utils/date";
import API from "../../api";
import {
  PersonIcon,
  PhoneIcon,
  NotificationsIcon,
  PropaneTankIcon,
  BusinessIcon,
  HomeIcon,
  InfoIcon,
  AddCommentIcon,
} from "../../theme/icons";
import { Link } from "react-router-dom";

const NoPrintWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;

const CustomCardWrapper = styled(Card)`
  padding: 20px;
  margin: 0 15px 20px;
  @media (max-width: 1080px) {
    margin: 0 0px 20px;
  }
`;

const WidgetHeader = styled.div`
  color: ${colors.gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

const ActvityItemContainer = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 12px 0;
`;

const ActivityDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ActivityUsername = styled.div`
  color: ${colors.grayDark};
  font-size: 12px;
`;

const ActivityAction = styled.div`
  color: ${colors.grayDarkText};
  font-weight: bold;
`;

const ActivityBody = styled.div`
  color: ${colors.black};
`;

const ActivityDateTime = styled.div`
  color: ${colors.black};
  font-size: 12px;
`;

const ActivityIcon = styled.div`
  color: ${colors.gray};
  svg {
    font-size: 22px;
  }
`;

const ActivityListWrapper = styled.div`
  overflow: auto;
  max-height: ${(props) => (props.$fullsize ? "100%" : "600px")};
  @media print {
    max-height: 100% !important;
  }
`;

const SkeletonBody = () => (
  <>
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
  </>
);

const RenderActivityIconForResource = ({ icon }) => {
  switch (icon) {
    case "organization":
      return <HomeIcon sx={{ color: colors.gray }} />;
    case "location":
      return <BusinessIcon sx={{ color: colors.gray }} />;
    case "user":
      return <PersonIcon sx={{ color: colors.gray }} />;
    case "alarm":
      return <NotificationsIcon sx={{ color: colors.gray }} />;
    case "oncall":
      return <PhoneIcon sx={{ color: colors.gray }} />;
    case "tank":
      return <PropaneTankIcon sx={{ color: colors.gray }} />;
    case "notification":
      return <NotificationsIcon sx={{ color: colors.gray }} />;
    case "comment":
      return <AddCommentIcon sx={{ color: colors.gray }} />;
    case "note":
      return <AddCommentIcon sx={{ color: colors.gray }} />;
    default:
      return <InfoIcon />;
  }
};

const ActivityList = ({ activity, fullsize }) => {
  return (
    <ActivityListWrapper $fullsize={fullsize}>
      {activity.map((item) => {
        return (
          <ActvityItemContainer key={item.id}>
            <ActivityIcon>
              <RenderActivityIconForResource icon={item.display.icon} />
            </ActivityIcon>
            <ActivityDetailsContainer>
              <ActivityAction>{item.display.action}</ActivityAction>
              <ActivityUsername>{item.display.userName}</ActivityUsername>
              <ActivityBody>{item.display.body}</ActivityBody>
              <ActivityDateTime>
                {formatDateTimeStamp(item.created)}
              </ActivityDateTime>
            </ActivityDetailsContainer>
          </ActvityItemContainer>
        );
      })}
    </ActivityListWrapper>
  );
};

const ActivityWidget = ({
  header = "Activity",
  resource,
  resourceId,
  organizationId,
  ready = false,
  showButton = true,
  fullsize = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [activity, setActivity] = useState([]);

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 60;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    if (ready) {
      if ((resource && resourceId) || organizationId) {
        if (!pollingStarted) {
          FetchData();
          PollData();
          setPollingStarted(true);
        }
      }
    }
  }, [resource, resourceId, organizationId, ready]);

  useEffect(() => {
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    await dataLayer.getActivity();
  };

  const dataLayer = {
    getActivity: async () => {
      let _activity = [];
      if (resource && resourceId) {
        _activity = await API.Activity.ActivityGet({
          resource,
          resourceId,
        });
      } else if (organizationId) {
        _activity = await API.Activity.ActivityGetAll({
          organizationId,
        });
      }

      _activity.forEach((item) => {
        item.display = BuildDisplayData({ item });
      });
      setActivity(_activity);
      setLoading(false);
    },
  };

  return (
    <CustomCardWrapper>
      <WidgetHeader>
        {header}
        {showButton && (
          <NoPrintWrapper>
            <Button
              sx={{
                boxShadow: 2,
                fontSize: "10px",
                padding: "4px 12px",
                minWidth: "auto",
              }}
              size="small"
              color="black"
              to={`/${organizationId}/activity`}
              component={Link}
            >
              View
            </Button>
          </NoPrintWrapper>
        )}
      </WidgetHeader>
      {loading ? (
        <SkeletonBody />
      ) : (
        <ActivityList fullsize={fullsize} activity={activity} />
      )}
    </CustomCardWrapper>
  );
};

export default ActivityWidget;

const BuildDisplayData = ({ item }) => {
  let display = { userName: item.userName, icon: item.resource };

  //alarmType Display Helper
  item.data.alarmTypeDisplayName = "";
  switch (item?.data?.alarmType) {
    case "low_weight":
      item.data.alarmTypeDisplayName = "Low Weight";
      break;
    case "weight_instability":
      item.data.alarmTypeDisplayName = "High Consumption Rate";
      break;
    case "low_temperature":
      item.data.alarmTypeDisplayName = "Low External Temperature";
      break;
    case "low_battery":
      item.data.alarmTypeDisplayName = "Low Battery";
      break;
    case "tank_offline":
      item.data.alarmTypeDisplayName = "Tank Offline";
      break;
    case "test_alarm":
      item.data.alarmTypeDisplayName = "Test Alarm";
      break;
    default: {
      item.data.alarmTypeDisplayName = item?.data?.alarmType;
    }
  }

  //Set Display Info
  switch (item.action) {
    //Organization Actions
    case "org_create":
      display.action = "Organization Created";
      display.body = <div>New organization {item.data.name} created</div>;
      break;
    case "org_update":
      display.action = "Organization Information Edited";
      display.body = <div>{item.data.name} information edited</div>;
      break;
    case "org_delete":
      display.action = "Organization Deleted";
      display.body = <div>{item.data.name} deleted</div>;
      break;
    case "org_deactivate":
      display.action = "Organization Deactivated";
      display.body = <div>{item.data.name} deactivated</div>;
      break;
    case "org_reactivate":
      display.action = "Organization Reactivated";
      display.body = <div>{item.data.name} reactivated</div>;
      break;
    //Location Actions
    case "location_create":
      display.action = "Location Created";
      display.body = <div>{item.data.name} created</div>;
      break;
    case "location_delete":
      display.action = "Location Deleted";
      display.body = <div>{item.data.name} deleted</div>;
      break;
    case "location_update":
      display.action = "Location Information Edited";
      display.body = <div>{item.data.name} information edited</div>;
      break;
    case "location_reactivate":
      display.action = "Location Reactivated";
      display.body = <div>{item.data.name} reactivated</div>;
      break;
    case "location_deactivate":
      display.action = "Location Deactivated";
      display.body = <div>{item.data.name} deactivated</div>;
      break;
    //Tank Actions
    case "tank_create":
      display.action = "Tank Created";
      display.body = <div>{item.data.name} created</div>;
      break;
    case "tank_delete":
      display.action = "Tank Deleted";
      display.body = <div>{item.data.name} deleted</div>;
      break;
    case "tank_deactivate":
      display.action = "Tank Deactivated";
      display.body = <div>{item.data.name} deactivated</div>;
      break;
    case "tank_reactivate":
      display.action = "Tank Reactivated";
      display.body = <div>{item.data.name} reactivated</div>;
      break;
    case "tank_update":
      display.action = "Tank Information Edited";
      display.body = <div>{item.data.name} information edited</div>;
      break;
    case "tank_create_note":
      display.action = "Tank Note Added";
      display.icon = "note";
      display.body = <div>{item.data.note}</div>;
      break;
    //Alarm Actions
    case "alarm_create":
      display.action = "New Alarm";
      display.body = (
        <div>
          {item.data.alarmTypeDisplayName} triggered on tank{" "}
          {item.data.tankName} in {item.data.locationName}
        </div>
      );
      break;
    case "alarm_delete":
      display.action = "Alarm Deleted";
      display.body = (
        <div>
          {item.data.alarmId} deleted on tank {item.data.tankName}
        </div>
      );
      break;
    case "alarm_reactivate":
      display.action = "Alarm Reactivated";
      display.body = (
        <div>
          {item.data.alarmId} reactivated tank {item.data.tankName}
        </div>
      );
      break;
    case "alarm_deactivate":
      display.action = "Alarm Deactivated";
      display.body = (
        <div>
          {item.data.alarmId} deactivated tank {item.data.tankName}
        </div>
      );
      break;
    case "alarm_comment_create":
      display.action = "New Alarm Comment";
      display.icon = "comment";
      display.body = (
        <div>
          {item.data.comment} on tank {item.data.tankName}
        </div>
      );
      break;
    case "alarm_resolution_status_update":
      display.action = "Alarm Resolution Status Updated";
      let statusString = item.data.resolutionStatus;
      switch (statusString) {
        case "resolved":
          statusString = "Resolved";
          break;
        case "progress":
          statusString = "In Progress";
          break;
        default:
          break;
      }
      display.body = (
        <div>
          {item.data.alarmTypeDisplayName} resolution status updated to{" "}
          {statusString} on tank {item.data?.tank?.name}
        </div>
      );
      break;
    //User Actions
    case "user_create":
      display.action = "User Created";
      display.body = (
        <div>
          New {item.data.role} created, {item.data.firstName}{" "}
          {item.data.lastName}
        </div>
      );
      break;
    case "user_delete":
      display.action = "User Deleted";
      display.body = (
        <div>
          {item.data.firstName} {item.data.lastName} deleted
        </div>
      );
      break;
    case "user_deactivate":
      display.action = "User Status Updated";
      display.body = (
        <div>
          {item.data.firstName} {item.data.lastName} deactivated
        </div>
      );
      break;
    case "user_reactivate":
      display.action = "User Status Updated";
      display.body = (
        <div>
          {item.data.firstName} {item.data.lastName} reactivated
        </div>
      );
      break;
    case "user_update":
      display.action = "User Information Edited";
      display.body = (
        <div>
          {item.data.firstName} {item.data.lastName} information edited
        </div>
      );
      break;
    //On Call Actions
    case "oncall_update_current":
      display.action = "Current On Call Updated";
      display.body = (
        <div>
          {item.data.user.firstName} {item.data.user.lastName} set to current on
          call for {item.data.location.name}
        </div>
      );
      break;
    case "oncall_update_backup_one":
      display.action = "Backup 1 On Call Updated";
      display.body = (
        <div>
          {item.data.user.firstName} {item.data.user.lastName} set to backup 1
          on call for {item.data.location.name}
        </div>
      );
      break;
    case "oncall_update_backup_two":
      display.action = "Backup 2 On Call Updated";
      display.body = (
        <div>
          {item.data.user.firstName} {item.data.user.lastName} set to backup 2
          on call for {item.data.location.name}
        </div>
      );
      break;
    //Notification Actions
    case "notification_create":
      display.action = "Alarm Notification Created";
      display.body = (
        <div>
          {item.data.alarmTypeDisplayName} alarm notification created on tank{" "}
          {item.data?.tank?.name}
        </div>
      );
      break;
    case "notification_sent":
      display.action = "Alarm Notification Sent";
      display.body = (
        <div>
          {item.data.alarmTypeDisplayName} alarm notification sent to{" "}
          {item.data.firstName} {item.data.lastName} (Email: {item.data.email})
          (Phone: {item.data.phone})
        </div>
      );
      break;
    case "notification_suppressed":
      display.action = "Alarm Notification Supressed";
      display.body = (
        <div>
          {item.data.alarmTypeDisplayName} alarm notification supressed on tank{" "}
          {item.data?.tank?.name}
        </div>
      );
      break;
    default:
      display = {
        icon: "info",
        action: item.action,
        userName: item.userName,
        body: JSON.stringify(item.data),
      };
  }

  return display;
};
