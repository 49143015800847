import { TextField, Button } from "@mui/material";
import FormFieldErrorClass from "./models/formFieldError";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import {
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  HeaderNote,
} from "./shared";
import Roles from "./shared/roles";

import {
  InternationalPhoneInput,
  isPhoneNumberValid,
} from "../PhoneNumberInput";

const OrganizationUserForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  loading = false,
  error = "",
  user,
  isEdit = false,
  showDeactivate = false,
  handleDeactivateUser = () => {},
  handleReactivateUser = () => {},
  handleResendInvite = () => {},
}) => {
  const [save, setSave] = useState(false);

  //Use the User object if provided
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneCountryData, setPhoneCountryData] = useState({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("TECH");

  const [firstNameError, setFirstNameError] = useState(
    new FormFieldErrorClass()
  );
  const [lastNameError, setLastNameError] = useState(new FormFieldErrorClass());
  const [phoneError, setPhoneError] = useState(new FormFieldErrorClass());
  const [emailError, setEmailError] = useState(new FormFieldErrorClass());
  const [roleError, setRoleError] = useState(new FormFieldErrorClass());

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone);
      setEmail(user.email);
      setRole(user.role);
    }
  }, [user]);

  const formFieldErrors = [
    firstNameError,
    lastNameError,
    phoneError,
    emailError,
    roleError,
  ];

  const validate = {
    firstName: (value) => {
      setFirstNameError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setFirstNameError({
          hasError: true,
          message: "First Name Required",
        });
        return;
      }
      if (value.length > 100) {
        setFirstNameError({
          hasError: true,
          message: "First Name Max 100 Charaters",
        });
        return;
      }
    },
    lastName: (value) => {
      setLastNameError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setLastNameError({
          hasError: true,
          message: "Last Name Required",
        });
        return;
      }
      if (value.length > 100) {
        setLastNameError({
          hasError: true,
          message: "Last Name Max 100 Charaters",
        });
        return;
      }
    },
    phone: (value) => {
      setPhoneError({
        hasError: false,
        message: "",
      });

      if (!value) {
        setPhoneError({
          hasError: true,
          message: "Phone Required",
        });
        return;
      }
      if (!isPhoneNumberValid(value)) {
        setPhoneError({
          hasError: true,
          message: "Phone Number Not Valid",
        });
        return;
      }
    },
    email: (value) => {
      setEmailError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setEmailError({
          hasError: true,
          message: "Email Required",
        });
        return;
      }
      if (!isEmail(value)) {
        setEmailError({
          hasError: true,
          message: "Email Format Not Valid",
        });
        return;
      }
    },
    role: (value) => {
      setRoleError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setRoleError({
          hasError: true,
          message: "Role Required",
        });
        return;
      }
    },
  };

  const handlers = {
    firstNameChange: (e) => {
      const value = e.target.value;
      validate.firstName(value);
      setFirstName(e.target.value);
    },
    lastNameChange: (e) => {
      const value = e.target.value;
      validate.lastName(value);
      setLastName(e.target.value);
    },
    phoneChange: (phoneData) => {
      console.log("phoneData", phoneData);
      validate.phone(phoneData.phone);
      setPhone(phoneData.phone);
      setPhoneCountryData(phoneData.country);
    },
    emailChange: (e) => {
      const value = e.target.value;
      validate.email(value);
      setEmail(e.target.value);
    },
    roleChange: (e) => {
      const value = e.target.value;
      validate.role(value);
      setRole(e.target.value);
    },
    save: () => {
      //validate all
      validate.firstName(firstName);
      validate.lastName(lastName);
      validate.phone(phone);
      validate.email(email);
      validate.role(role);
      setSave(true);
    },
    cancel: () => {
      handleCancel();
    },
    deactivateUser: () => {
      handleDeactivateUser(user);
    },
    reactivateUser: () => {
      handleReactivateUser(user);
    },
    resendUserInvite: () => {
      handleResendInvite(user);
    },
  };

  useEffect(() => {
    if (save) {
      setSave(false);
      if (formFieldErrors.filter((e) => e.hasError).length) {
        return;
      }

      const phoneCountryDialCode = phoneCountryData.dialCode;
      const phoneCountryCode = phoneCountryData.iso2;

      //send data
      handleSave({
        firstName,
        lastName,
        phone,
        phoneCountryDialCode,
        phoneCountryCode,
        email,
        role,
      });
    }
  }, [save, formFieldErrors]);

  return (
    <>
      <FormContainer>
        <HeaderNote>All fields required</HeaderNote>
        <TextField
          id="first-name"
          label="First Name"
          variant="outlined"
          value={firstName}
          onChange={handlers.firstNameChange}
          helperText={firstNameError.message}
          error={firstNameError.hasError}
          disabled={loading}
        />
        <TextField
          id="last-name"
          label="Last Name"
          variant="outlined"
          value={lastName}
          onChange={handlers.lastNameChange}
          helperText={lastNameError.message}
          error={lastNameError.hasError}
          disabled={loading}
        />

        <InternationalPhoneInput
          value={phone}
          onChange={handlers.phoneChange}
          helperText={phoneError.message}
          error={phoneError.hasError}
          disabled={loading}
          isMobile={true}
        />

        <TextField
          id="email-address"
          label="Email Address"
          variant="outlined"
          value={email}
          type="email"
          onChange={handlers.emailChange}
          helperText={emailError.message}
          error={emailError.hasError}
          disabled={loading || isEdit}
        />

        <TextField
          id="role"
          select
          variant="outlined"
          label="Role"
          value={role}
          helperText={roleError.message}
          error={roleError.hasError}
          SelectProps={{
            native: true,
          }}
          onChange={handlers.roleChange}
        >
          {Roles.map((role) => {
            return (
              <option key={role.value} value={role.value}>
                {role.title}
              </option>
            );
          })}
        </TextField>
      </FormContainer>

      {showDeactivate && (
        <>
          {isEdit && user.deleted && (
            <Button
              variant="outlined"
              color="error"
              onClick={handlers.reactivateUser}
              disabled={loading}
            >
              REACTIVATE USER
            </Button>
          )}
          {isEdit && !user.deleted && (
            <Button
              variant="outlined"
              color="error"
              onClick={handlers.deactivateUser}
              disabled={loading}
            >
              DEACTIVATE USER
            </Button>
          )}
        </>
      )}

      {isEdit && user?.invitePending && (
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          // color="error"
          onClick={handlers.resendUserInvite}
          disabled={loading}
        >
          RESEND INVITE EMAIL
        </Button>
      )}

      {error && (
        <FormSubmitError>
          <ErrorIcon fontSize="small" />
          {error}
        </FormSubmitError>
      )}

      <ActionButtonsContainer>
        <Button onClick={handlers.cancel}>Cancel</Button>
        {loading ? (
          <Button disabled variant="contained">
            Saving&#8230;
          </Button>
        ) : (
          <Button onClick={handlers.save} variant="contained">
            Save
          </Button>
        )}
      </ActionButtonsContainer>
    </>
  );
};

export default OrganizationUserForm;
