import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  Card,
  Switch,
} from "@mui/material";
import FormFieldErrorClass from "./models/formFieldError";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import {
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  InputFieldsWrapper,
} from "./shared";
import styled from "styled-components";
import colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  add as addNotification,
  remove as removeNotification,
} from "../../redux-store/notificationsSlice";
import isNumeric from "validator/lib/isNumeric";
import { FixedTwoDecimalPlaces } from "../../utils/numbers";

const SubSectionHeader = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${colors.gray};
  margin: 20px 0;
  font-weight: 500;
  &.no-bottom-margin {
    margin: 20px 0 0 0;
  }
`;

const ActionButtonsContainerWithSpace = styled(ActionButtonsContainer)`
  margin: 35px 0 60px;
`;

const TankAlarmDetailsContainer = styled(Card)`
  padding: 20px;
`;

const DetailKey = styled.div`
  color: ${colors.gray};
`;

const DetailValue = styled.div`
  color: ${colors.grayDark};
  margin-bottom: 4px;
`;

const InlineToolTip = styled.div`
  font-size: 12px;
`;

const DetailSubHeader = styled.div`
  font-size: 12px;
  margin-bottom: 12px;
`;

const DetailHeader = styled.div``;

const CustomToggleFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrganizationTankForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  loading = false,
  error = "",
  tank,
  locations = [],
  contents = [],
  types = [],
  makeAndModel = [],
  standardAlarmValues = {},
  isEdit = false,
  showDeactivate = true,
  handleDeactivateTank = () => {},
  handleReactivateTank = () => {},
}) => {
  const dispatch = useDispatch();
  const { isAdminUser } = useSelector((state) => state.ux);

  useEffect(() => {
    if (locations.length) {
      setLocationId(locations[0].id);
    }
  }, [locations]);

  useEffect(() => {
    if (contents.length) {
      setContentsId(contents[0].id);
    }
  }, [contents]);

  useEffect(() => {
    if (types.length) {
      setTypeId(types[0].id);
    }
  }, [types]);

  //UX State
  const [modelChanged, setModelChanged] = useState(false);
  const [save, setSave] = useState(false);

  const testMode = false;
  const initValue = testMode ? "test" : "";

  //Form Fields
  // // Tank Details
  const [name, setName] = useState(initValue);
  const [locationId, setLocationId] = useState("");
  const [scaleId, setScaleId] = useState(initValue);
  const [contentsId, setContentsId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [serialNumber, setSerialNumber] = useState(initValue);
  const [make, setMake] = useState(makeAndModel[0].id);
  const [model, setModel] = useState(makeAndModel[0].models[0].id);
  // // Tank Alarm Details

  //Full Weight
  const [fullWeightKG, setFullWeightKG] = useState(initValue);
  const [useCustomFullWeightKG, setUseCustomFullWeightKG] = useState(false);
  //Low Weight
  const [alarmLowLevelWeightKg, setAlarmLowLevelWeightKg] = useState(initValue);
  const [useCustomAlarmLowLevelWeightKg, setUseCustomAlarmLowLevelWeightKg] =
    useState(false);
  //Consumption Rate
  const [alarmLNConsumptionRateKg, setAlarmLNConsumptionRateKg] = useState(
    standardAlarmValues.consumptionRate.toString()
  );
  const [
    useCustomAlarmLNConsumptionRateKg,
    setUseCustomAlarmLNConsumptionRateKg,
  ] = useState(false);
  //Low Temp
  const [alarmTempRangeLow, setAlarmTempRangeLow] = useState(
    standardAlarmValues.temperatureRangeLow.toString()
  );
  const [useCustomAlarmTempRangeLow, setUseCustomAlarmTempRangeLow] =
    useState(false);
  // High Temp
  const [alarmTempRangeHigh, setAlarmTempRangeHigh] = useState(
    standardAlarmValues.temperatureRangeHigh.toString()
  );
  const [useCustomAlarmTempRangeHigh, setUseCustomAlarmTempRangeHigh] =
    useState(false);

  const now = dayjs();
  const [installDate, setInstallDate] = useState(now);
  const [serviceStatus, setServiceStatus] = useState("active");

  //Error State
  // // Tank Details
  const [nameError, setNameError] = useState(new FormFieldErrorClass());
  const [locationIdError, setLocationIdError] = useState(
    new FormFieldErrorClass()
  );
  const [scaleIdError, setScaleIdError] = useState(new FormFieldErrorClass());
  const [contentsIdError, setContentsIdError] = useState(
    new FormFieldErrorClass()
  );
  const [typeIdError, setTypeIdError] = useState(new FormFieldErrorClass());
  const [serialNumberError, setSerialNumberError] = useState(
    new FormFieldErrorClass()
  );

  const [makeError, setMakeError] = useState(new FormFieldErrorClass());
  const [modelError, setModelError] = useState(new FormFieldErrorClass());
  // // Tank Alarm Details
  const [fullWeightKGError, setFullWeightKGError] = useState(
    new FormFieldErrorClass()
  );
  const [alarmLowLevelWeightKgError, setAlarmLowLevelWeightKgError] = useState(
    new FormFieldErrorClass()
  );
  const [alarmLNConsumptionRateKgError, setAlarmLNConsumptionRateKgError] =
    useState(new FormFieldErrorClass());
  const [alarmTempRangeLowError, setAlarmTempRangeLowError] = useState(
    new FormFieldErrorClass()
  );
  const [alarmTempRangeHighError, setAlarmTempRangeHighError] = useState(
    new FormFieldErrorClass()
  );
  const [installDateError, setInstallDateError] = useState(
    new FormFieldErrorClass()
  );
  const [serviceStatusError, setServiceStatusError] = useState(
    new FormFieldErrorClass()
  );

  const formFieldErrors = [
    nameError,
    locationIdError,
    scaleIdError,
    contentsIdError,
    typeIdError,
    serialNumberError,
    makeError,
    modelError,
    fullWeightKGError,
    alarmLowLevelWeightKgError,
    alarmLNConsumptionRateKgError,
    alarmTempRangeLowError,
    alarmTempRangeHighError,
    installDateError,
    serviceStatusError,
  ];

  useEffect(() => {
    if (isEdit && tank) {
      setName(tank?.name || "");
      setLocationId(tank?.locationId || "");
      setScaleId(tank?.scaleId || "");
      setContentsId(tank?.contentsId || "");
      setTypeId(tank?.typeId || "");
      setSerialNumber(tank?.serialNumber || "");
      setMake(tank?.make || "");
      setModel(tank?.model || "");

      setFullWeightKG(tank?.fullWeightKG || "");
      setAlarmLowLevelWeightKg(tank?.alarmLowLevelWeightKg || "");

      setUseCustomFullWeightKG(tank?.useCustomFullWeightKG);

      setUseCustomAlarmLowLevelWeightKg(tank?.useCustomAlarmLowLevelWeightKg);

      const _installDate = dayjs(tank?.installDate);
      setInstallDate(_installDate);
      setServiceStatus(tank?.serviceStatus || "");

      //Disabled Until Firmware can handle
      setUseCustomAlarmTempRangeLow(tank?.useCustomAlarmTempRangeLow);
      // setUseCustomAlarmTempRangeHigh(tank?.useCustomAlarmTempRangeHigh);

      setAlarmTempRangeLow(tank?.alarmTempRangeLow || "");
      // setAlarmTempRangeHigh(tank?.alarmTempRangeHigh || "");

      setAlarmLNConsumptionRateKg(tank?.alarmLNConsumptionRateKg || "");
      setUseCustomAlarmLNConsumptionRateKg(
        tank?.useCustomAlarmLNConsumptionRateKg
      );
    }
  }, [tank, isEdit]);

  useEffect(() => {
    //if the make changes set the inital model value to the first in the list
    if (isEdit && modelChanged) {
      const _model = makeAndModel.find((m) => m.id === make).models[0];
      setModel(_model.id);
      setFullWeightKG(_model.fullWeightKg);
      setAlarmLowLevelWeightKg(_model.lowWeightKg);
    } else if (!isEdit) {
      const _model = makeAndModel.find((m) => m.id === make).models[0];
      setModel(_model.id);
      setFullWeightKG(_model.fullWeightKg.toString());
      setAlarmLowLevelWeightKg(_model.lowWeightKg.toString());
      validate.fullWeightKG(_model.fullWeightKg.toString());
      validate.alarmLowLevelWeightKg(_model.lowWeightKg.toString());
    }
  }, [make]);

  useEffect(() => {
    //if the model changes set the inital model value to the first in the list
    if (model && isEdit && modelChanged) {
      const _model = makeAndModel
        .find((m) => m.id === make)
        .models.find((m) => m.id === model);
      setFullWeightKG(_model.fullWeightKg.toString());
      setAlarmLowLevelWeightKg(_model.lowWeightKg.toString());
      validate.fullWeightKG(_model.fullWeightKg.toString());
      validate.alarmLowLevelWeightKg(_model.lowWeightKg.toString());
    } else if (model && !isEdit) {
      const _model = makeAndModel
        .find((m) => m.id === make)
        .models.find((m) => m.id === model);
      setFullWeightKG(_model.fullWeightKg.toString());
      setAlarmLowLevelWeightKg(_model.lowWeightKg.toString());
      validate.fullWeightKG(_model.fullWeightKg.toString());
      validate.alarmLowLevelWeightKg(_model.lowWeightKg.toString());
    }
  }, [model, isEdit]);

  useEffect(() => {
    if (save) {
      setSave(false);
      if (formFieldErrors.filter((e) => e.hasError).length) {
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Form Error",
            message: "Please check all form inputs for errors",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 4000);
        return;
      }

      //send data
      handleSave({
        name,
        locationId,
        scaleId,
        contentsId,
        typeId,
        serialNumber,
        make,
        model,
        fullWeightKG,
        alarmLowLevelWeightKg,
        alarmLNConsumptionRateKg,
        alarmTempRangeHigh,
        alarmTempRangeLow,
        useCustomFullWeightKG,
        useCustomAlarmLowLevelWeightKg,
        useCustomAlarmLNConsumptionRateKg,
        useCustomAlarmTempRangeLow,
        useCustomAlarmTempRangeHigh,
        installDate: installDate.valueOf(),
        serviceStatus,
      });
    }
  }, [save, formFieldErrors]);

  // useEffect(() => {
  //   if (!useCustomFullWeightKG) {
  //     resetDefaultValues.fullWeightKG();
  //   }
  // }, [useCustomFullWeightKG]);

  // useEffect(() => {
  //   if (!useCustomAlarmLowLevelWeightKg) {
  //     resetDefaultValues.alarmLowLevelWeightKg();
  //   }
  // }, [useCustomAlarmLowLevelWeightKg]);

  // useEffect(() => {
  //   if (!useCustomAlarmLNConsumptionRateKg) {
  //     resetDefaultValues.alarmLNConsumptionRateKg();
  //   }
  // }, [useCustomAlarmLNConsumptionRateKg]);

  // useEffect(() => {
  //   if (!useCustomAlarmTempRangeLow) {
  //     resetDefaultValues.alarmTempRangeLow();
  //   }
  // }, [useCustomAlarmTempRangeLow]);

  // useEffect(() => {
  //   if (!useCustomAlarmTempRangeHigh) {
  //     resetDefaultValues.alarmTempRangeHigh();
  //   }
  // }, [useCustomAlarmTempRangeHigh]);

  const resetDefaultValues = {
    fullWeightKG: () => {
      const _model = makeAndModel
        .find((m) => m.id === make)
        .models.find((m) => m.id === model);
      setFullWeightKG(_model.fullWeightKg.toString());
      validate.fullWeightKG(_model.fullWeightKg.toString());
    },
    alarmLowLevelWeightKg: () => {
      const _model = makeAndModel
        .find((m) => m.id === make)
        .models.find((m) => m.id === model);
      setAlarmLowLevelWeightKg(_model.lowWeightKg.toString());
      validate.alarmLowLevelWeightKg(_model.lowWeightKg.toString());
    },
    alarmLNConsumptionRateKg: () => {
      setAlarmLNConsumptionRateKg(
        standardAlarmValues.consumptionRate.toString()
      );
      validate.alarmLNConsumptionRateKg(
        standardAlarmValues.consumptionRate.toString()
      );
    },
    alarmTempRangeLow: () => {
      const lowTemp = standardAlarmValues.temperatureRangeLow.toString();
      setAlarmTempRangeLow(lowTemp);
      validate.alarmTempRangeLow(lowTemp, alarmTempRangeHigh);
      validate.alarmTempRangeHigh(lowTemp, alarmTempRangeHigh);
    },
    alarmTempRangeHigh: () => {
      const highTemp = standardAlarmValues.temperatureRangeHigh.toString();
      setAlarmTempRangeHigh(highTemp);
      validate.alarmTempRangeHigh(alarmTempRangeLow, highTemp);
      validate.alarmTempRangeLow(alarmTempRangeLow, highTemp);
    },
  };
  const validate = {
    name: (value) => {
      setNameError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setNameError({
          hasError: true,
          message: "Tank Name Required",
        });
        return;
      }
      if (value.length > 100) {
        setNameError({
          hasError: true,
          message: "Tank Name Max 100 Characters",
        });
        return;
      }
    },
    locationId: (value) => {
      setLocationIdError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setLocationIdError({
          hasError: true,
          message: "Location Required",
        });
        return;
      }
    },
    scaleId: (value) => {
      setScaleIdError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setScaleIdError({
          hasError: true,
          message: "Scale ID Required",
        });
        return;
      }
      if (value.length > 100) {
        setScaleIdError({
          hasError: true,
          message: "Scale ID Max 100 Characters",
        });
        return;
      }
    },
    contents: (value) => {
      setContentsIdError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setContentsIdError({
          hasError: true,
          message: "Tank Contents Required",
        });
        return;
      }
    },
    typeId: (value) => {
      setTypeIdError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setTypeIdError({
          hasError: true,
          message: "Tank Type Required",
        });
        return;
      }
    },
    serialNumber: (value) => {
      setSerialNumberError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setSerialNumberError({
          hasError: true,
          message: "Serial Number Required",
        });
        return;
      }
    },
    make: (value) => {
      setMakeError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setMakeError({
          hasError: true,
          message: "Tank Make Required",
        });
        return;
      }
    },
    model: (value) => {
      setModelError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setModelError({
          hasError: true,
          message: "Tank Model Required",
        });
        return;
      }
    },
    fullWeightKG: (value) => {
      setFullWeightKGError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setFullWeightKGError({
          hasError: true,
          message: "Full Weight Required",
        });
        return;
      }
      if (!isNumeric(value)) {
        setFullWeightKGError({
          hasError: true,
          message: "Value Must Be Numeric",
        });
        return;
      }
    },
    alarmLowLevelWeightKg: (value) => {
      setAlarmLowLevelWeightKgError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setAlarmLowLevelWeightKgError({
          hasError: true,
          message: "Low Weight Alarm Required",
        });
        return;
      }
      if (!isNumeric(value)) {
        setAlarmLowLevelWeightKgError({
          hasError: true,
          message: "Value Must Be Numeric",
        });
        return;
      }
    },
    alarmLNConsumptionRateKg: (value) => {
      setAlarmLNConsumptionRateKgError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setAlarmLNConsumptionRateKgError({
          hasError: true,
          message: "LN Consumption Alarm Required",
        });
        return;
      }
      if (!isNumeric(value)) {
        setAlarmLNConsumptionRateKgError({
          hasError: true,
          message: "Value Must Be Numeric",
        });
        return;
      }

      if (parseFloat(value) > 4.8) {
        setAlarmLNConsumptionRateKgError({
          hasError: true,
          message: "Max Value is 4.8 kg/day",
        });
        return;
      }

      if (parseFloat(value) < 0.9) {
        setAlarmLNConsumptionRateKgError({
          hasError: true,
          message: "Minimum Value is 0.9 kg/day",
        });
        return;
      }
    },
    alarmTempRangeLow: (lowValue, highValue) => {
      setAlarmTempRangeLowError({
        hasError: false,
        message: "",
      });
      if (!lowValue) {
        setAlarmTempRangeLowError({
          hasError: true,
          message: "Temperature Range Low Required",
        });
        return;
      }
      if (!isNumeric(lowValue)) {
        setAlarmTempRangeLowError({
          hasError: true,
          message: "Value Must Be Numeric",
        });
        return;
      }
      if (
        parseFloat(lowValue) > parseFloat(highValue) ||
        parseFloat(lowValue) === parseFloat(highValue)
      ) {
        setAlarmTempRangeLowError({
          hasError: true,
          message: "Min Value Must Be Lower Than Max Value",
        });
        return;
      }
    },
    alarmTempRangeHigh: (lowValue, highValue) => {
      setAlarmTempRangeHighError({
        hasError: false,
        message: "",
      });
      if (!highValue) {
        setAlarmTempRangeHighError({
          hasError: true,
          message: "Temperature Range High Required",
        });
        return;
      }
      if (!isNumeric(highValue)) {
        setAlarmTempRangeHighError({
          hasError: true,
          message: "Value Must Be Numeric",
        });
        return;
      }
      if (
        parseFloat(highValue) < parseFloat(lowValue) ||
        parseFloat(highValue) === parseFloat(lowValue)
      ) {
        setAlarmTempRangeHighError({
          hasError: true,
          message: "Max Value Must Be Greater Than Min Value",
        });
        return;
      }
    },
    installDate: (value) => {
      setInstallDateError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setInstallDate(now);
        setInstallDateError({
          hasError: true,
          message: "Install Date Required",
        });
        return;
      }
    },
    serviceStatus: (value) => {
      setServiceStatusError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setServiceStatusError({
          hasError: true,
          message: "Service Status Required",
        });
        return;
      }
    },
  };
  const handlers = {
    nameChange: ({ target }) => {
      const { value } = target;
      validate.name(value);
      setName(value);
    },
    locationIdChange: ({ target }) => {
      const { value } = target;
      validate.locationId(value);
      setLocationId(value);
    },
    scaleIdChange: ({ target }) => {
      const { value } = target;
      //remove spaces
      const scaleId = value.split(" ").join("");
      validate.scaleId(scaleId);
      setScaleId(scaleId);
    },
    contentsIdChange: ({ target }) => {
      const { value } = target;
      validate.contents(value);
      setContentsId(value);
    },
    typeIdChange: ({ target }) => {
      const { value } = target;
      validate.typeId(value);
      setTypeId(value);
    },
    serialNumberChange: ({ target }) => {
      const { value } = target;
      validate.serialNumber(value);
      setSerialNumber(value);
    },
    makeChange: ({ target }) => {
      const { value } = target;
      validate.make(value);
      setMake(value);
      setModelChanged(true);
    },
    modelChange: ({ target }) => {
      const { value } = target;
      validate.model(value);
      setModel(value);
      setModelChanged(true);
    },
    fullWeightKGChange: ({ target }) => {
      let { value } = target;
      value = FixedTwoDecimalPlaces(value);
      validate.fullWeightKG(value);
      setFullWeightKG(value);
    },
    customFullWeightKGToggle: ({ target }) => {
      const { checked } = target;
      setUseCustomFullWeightKG(checked);
      if (!checked) {
        resetDefaultValues.fullWeightKG();
      } else if (isEdit) {
        setFullWeightKG(tank?.fullWeightKG);
      }
    },
    alarmLowLevelWeightKgChange: ({ target }) => {
      let { value } = target;
      value = FixedTwoDecimalPlaces(value);
      validate.alarmLowLevelWeightKg(value);
      setAlarmLowLevelWeightKg(value);
    },
    customAlarmLowLevelWeightKgToggle: ({ target }) => {
      const { checked } = target;
      setUseCustomAlarmLowLevelWeightKg(checked);
      if (!checked) {
        resetDefaultValues.alarmLowLevelWeightKg();
      } else if (isEdit) {
        setAlarmLowLevelWeightKg(tank?.alarmLowLevelWeightKg);
      }
    },
    alarmLNConsumptionRateKgChange: ({ target }) => {
      let { value } = target;
      value = FixedTwoDecimalPlaces(value);
      validate.alarmLNConsumptionRateKg(value);
      setAlarmLNConsumptionRateKg(value);
    },
    customAlarmLNConsumptionRateKgToggle: ({ target }) => {
      const { checked } = target;
      setUseCustomAlarmLNConsumptionRateKg(checked);
      if (!checked) {
        resetDefaultValues.alarmLNConsumptionRateKg();
      } else if (isEdit) {
        setAlarmLNConsumptionRateKg(tank?.alarmLNConsumptionRateKg);
      }
    },
    alarmTempRangeLowChange: ({ target }) => {
      let { value } = target;
      value = FixedTwoDecimalPlaces(value);
      validate.alarmTempRangeLow(value, alarmTempRangeHigh);
      setAlarmTempRangeLow(value);
      validate.alarmTempRangeHigh(value, alarmTempRangeHigh);
    },
    customAlarmTempRangeLowToggle: ({ target }) => {
      const { checked } = target;
      setUseCustomAlarmTempRangeLow(checked);
      if (!checked) {
        resetDefaultValues.alarmTempRangeLow();
      } else if (isEdit) {
        setAlarmTempRangeLow(tank?.alarmTempRangeLow);
      }
    },
    alarmTempRangeHighChange: ({ target }) => {
      let { value } = target;
      value = FixedTwoDecimalPlaces(value);
      validate.alarmTempRangeHigh(alarmTempRangeLow, value);
      setAlarmTempRangeHigh(value);
      validate.alarmTempRangeLow(alarmTempRangeLow, value);
    },
    customAlarmTempRangeHighToggle: ({ target }) => {
      const { checked } = target;
      setUseCustomAlarmTempRangeHigh(checked);
      if (!checked) {
        resetDefaultValues.alarmTempRangeHigh();
      } else if (isEdit) {
        setAlarmTempRangeHigh(tank?.alarmTempRangeHigh);
      }
    },
    installDateChange: (value) => {
      setInstallDate(value);
      validate.installDate(value);
    },
    serviceStatusChange: ({ target }) => {
      const { value } = target;
      validate.serviceStatus(value);
      setServiceStatus(value);
    },
    save: () => {
      //validate all
      validate.name(name);
      validate.locationId(locationId);
      validate.scaleId(scaleId);
      validate.contents(contents);
      validate.typeId(typeId);
      validate.serialNumber(serialNumber);
      validate.make(make);
      validate.model(model);
      validate.fullWeightKG(fullWeightKG);
      validate.alarmLowLevelWeightKg(alarmLowLevelWeightKg);
      validate.alarmLNConsumptionRateKg(alarmLNConsumptionRateKg);
      validate.alarmTempRangeLow(alarmTempRangeLow, alarmTempRangeHigh);
      validate.alarmTempRangeHigh(alarmTempRangeLow, alarmTempRangeHigh);
      validate.installDate(installDate);
      validate.serviceStatus(serviceStatus);

      setSave(true);
    },
    cancel: () => {
      handleCancel();
    },
    deactivateTank: () => {
      handleDeactivateTank(tank);
    },
    reactivateTank: () => {
      handleReactivateTank(tank);
    },
  };

  return (
    <>
      <FormContainer>
        {/* <HeaderNote>All fields required</HeaderNote> */}
        <InputFieldsWrapper>
          <SubSectionHeader>Tank Details</SubSectionHeader>
          <TextField
            id="tank-name"
            label="Tank Name"
            variant="outlined"
            value={name}
            onChange={handlers.nameChange}
            helperText={nameError.message}
            error={nameError.hasError}
          />
          <TextField
            id="tank-locationid"
            select
            variant="outlined"
            label="Tank Location"
            value={locationId}
            helperText={locationIdError.message}
            error={locationIdError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.locationIdChange}
          >
            {locations.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </TextField>
          <TextField
            id="tank-scale-id"
            label="Scale ID"
            variant="outlined"
            value={scaleId}
            onChange={handlers.scaleIdChange}
            helperText={scaleIdError.message}
            error={scaleIdError.hasError}
          />
          <TextField
            id="tank-contents"
            select
            variant="outlined"
            label="Tank Contents"
            value={contentsId}
            helperText={contentsIdError.message}
            error={contentsIdError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.contentsIdChange}
          >
            {contents.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </TextField>
          <TextField
            id="tank-typeId"
            select
            variant="outlined"
            label="Tank Type"
            value={typeId}
            helperText={typeIdError.message}
            error={typeIdError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.typeIdChange}
          >
            {types.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </TextField>
          <TextField
            id="tank-serial-number"
            label="Tank Serial Number"
            variant="outlined"
            value={serialNumber}
            onChange={handlers.serialNumberChange}
            helperText={serialNumberError.message}
            error={serialNumberError.hasError}
          />
          <TextField
            id="tank-make"
            select
            variant="outlined"
            label="Tank Make"
            value={make}
            helperText={makeError.message}
            error={makeError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.makeChange}
          >
            {makeAndModel.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </TextField>
          <TextField
            id="tank-model"
            select
            variant="outlined"
            label="Tank Model"
            value={model}
            helperText={modelError.message}
            error={modelError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.modelChange}
          >
            {makeAndModel
              .find((m) => m.id === make)
              .models.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </TextField>
          <SubSectionHeader className="no-bottom-margin">
            Tank Alarm Details
          </SubSectionHeader>
          <DetailSubHeader>
            These values are dynamically assigned based on the Make and Model
            selection, and are used to trigger alarm notifications. The
            thresholds below are guides based on the make and model you
            selected. These can be customized to fit your individual tank needs
            by toggling “Use Custom Value” and entering your custom value. To
            return to default threshold, simply toggle back off for your
            respective value.
          </DetailSubHeader>
          <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-full-weight-kg"
              label="Full Weight (kg)"
              variant="outlined"
              value={fullWeightKG}
              onChange={handlers.fullWeightKGChange}
              helperText={fullWeightKGError.message}
              error={fullWeightKGError.hasError}
              disabled={!useCustomFullWeightKG}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">kg</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              labelPlacement="top"
              control={<Switch checked={useCustomFullWeightKG} />}
              label="Use Custom Value"
              onChange={handlers.customFullWeightKGToggle}
            />
          </CustomToggleFieldContainer>

          <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-alarm-low-level-weight-kg"
              label="Low Weight Level Alarm Threshold (kg)"
              variant="outlined"
              value={alarmLowLevelWeightKg}
              onChange={handlers.alarmLowLevelWeightKgChange}
              helperText={alarmLowLevelWeightKgError.message}
              error={alarmLowLevelWeightKgError.hasError}
              disabled={!useCustomAlarmLowLevelWeightKg}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">kg</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              labelPlacement="top"
              control={<Switch checked={useCustomAlarmLowLevelWeightKg} />}
              label="Use Custom Value"
              onChange={handlers.customAlarmLowLevelWeightKgToggle}
            />
          </CustomToggleFieldContainer>

          <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-alarm-ln-consumption-weight-kg"
              label="Consumption Rate Alarm Threshold (kg/day)"
              variant="outlined"
              value={alarmLNConsumptionRateKg}
              onChange={handlers.alarmLNConsumptionRateKgChange}
              helperText={alarmLNConsumptionRateKgError.message}
              error={alarmLNConsumptionRateKgError.hasError}
              disabled={!useCustomAlarmLNConsumptionRateKg}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">kg/day</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              labelPlacement="top"
              control={<Switch checked={useCustomAlarmLNConsumptionRateKg} />}
              label="Use Custom Value"
              onChange={handlers.customAlarmLNConsumptionRateKgToggle}
            />
          </CustomToggleFieldContainer>
          {/* 
          <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-alarm-temp-min"
              label="Acceptable Minimum External Temperature (&#8451;)"
              variant="outlined"
              value={alarmTempRangeLow}
              onChange={handlers.alarmTempRangeLowChange}
              helperText={alarmTempRangeLowError.message}
              error={alarmTempRangeLowError.hasError}
              // disabled={!useCustomAlarmTempRangeLow}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">&#8451;</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              disabled={true}
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              labelPlacement="top"
              control={<Switch checked={useCustomAlarmTempRangeLow} />}
              label="Use Custom Value"
              onChange={handlers.customAlarmTempRangeLowToggle}
            />
          </CustomToggleFieldContainer> */}

          <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-external-min-temp"
              select
              disabled={!useCustomAlarmTempRangeLow}
              variant="outlined"
              label="Acceptable Minimum External Temperature (&#8451;)"
              value={alarmTempRangeLow}
              helperText={alarmTempRangeLowError.message}
              error={alarmTempRangeLowError.hasError}
              SelectProps={{
                native: true,
              }}
              onChange={handlers.alarmTempRangeLowChange}
            >
              <option key={"15c"} value={"15"}>
                15 (&#8451;)
              </option>{" "}
              <option key={"12.5c"} value={"12.5"}>
                12.5 (&#8451;)
              </option>
              <option key={"10c"} value={"10"}>
                10 (&#8451;)
              </option>
              <option key={"5c"} value={"5"}>
                5 (&#8451;)
              </option>
              <option key={"0c"} value={"0"}>
                0 (&#8451;)
              </option>
            </TextField>
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              labelPlacement="top"
              control={<Switch checked={useCustomAlarmTempRangeLow} />}
              label="Use Custom Value"
              onChange={handlers.customAlarmTempRangeLowToggle}
            />
          </CustomToggleFieldContainer>

          {/* <CustomToggleFieldContainer>
            <TextField
              sx={{ width: "100%" }}
              id="tank-alarm-temp-max"
              label="Acceptable Maximum External Temperature (&#8451;)"
              variant="outlined"
              value={alarmTempRangeHigh}
              onChange={handlers.alarmTempRangeHighChange}
              helperText={alarmTempRangeHighError.message}
              error={alarmTempRangeHighError.hasError}
              // disabled={!useCustomAlarmTempRangeHigh}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">&#8451;</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              sx={{
                alignItems: "flex-start",
                width: "200px",
              }}
              disabled={true}
              labelPlacement="top"
              control={
                <Switch
                  // checked={useCustomAlarmTempRangeHigh}
                  checked={false}
                />
              }
              label="Use Custom Value"
              // onChange={handlers.customAlarmTempRangeHighToggle}
            />
          </CustomToggleFieldContainer> */}
          {/* 
          <TankAlarmDetailsContainer>
            <DetailHeader>Tank Alarm Details</DetailHeader>
            <DetailSubHeader>
              These values are used to trigger alarms in the Boreas system for
              this tank.
            </DetailSubHeader>

            <DetailKey>Full Weight</DetailKey>
            <DetailValue>{fullWeightKG || "-"} kg</DetailValue>

            <DetailKey>Low Level Alarm Weight</DetailKey>
            <DetailValue>
              {"<"} {alarmLowLevelWeightKg || "-"} kg
            </DetailValue>

            <DetailKey>Consumption Rate Alarm</DetailKey>
            <DetailValue>
              {">"} {alarmLNConsumptionRateKg} kg/hr
            </DetailValue>

            <DetailKey>Acceptable Temperature Range</DetailKey>
            <DetailValue>
              {alarmTempRangeLow} - {alarmTempRangeHigh} &#8451;
            </DetailValue>
          </TankAlarmDetailsContainer> */}
          <SubSectionHeader>Addtional Details</SubSectionHeader>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="tank-install-date"
              label="Install Date"
              value={installDate}
              onChange={handlers.installDateChange}
              onError={(message) => {
                if (message === "invalidDate") {
                }
                const messageString =
                  message === "invalidDate"
                    ? "Invalid Date Format - Click Calendar Icon to Select Date"
                    : "";
                setInstallDateError({
                  hasError: true,
                  message: messageString,
                });
              }}
              slotProps={{
                textField: {
                  helperText: installDateError.message,
                },
              }}
            />
          </LocalizationProvider>

          <FormControl>
            <FormLabel id="service-status-group">Tank Status</FormLabel>
            <RadioGroup
              aria-labelledby="service-status-group"
              name="service-status-group-name"
              value={serviceStatus}
              onChange={handlers.serviceStatusChange}
            >
              {isAdminUser ? (
                <>
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <InlineToolTip>
                    When a tank is set to 'Active', data is being collected and
                    alarms are triggered as normal
                  </InlineToolTip>
                  <FormControlLabel
                    value="maintenance"
                    control={<Radio />}
                    label="Maintenance Mode"
                  />
                  <InlineToolTip>
                    When a tank's status is set to 'Maintenance', data will be
                    collected however alarms will not sound as long as the tank
                    is set to Maintenance. Maintenance mode should be turned on
                    if a tank is being worked on, filled, or any other activity
                    is happening that could falsely trigger alarms
                  </InlineToolTip>
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <InlineToolTip>
                    When a tank is set to 'Active', data is being collected and
                    alarms are triggered as normal
                  </InlineToolTip>
                  <FormControlLabel
                    value="maintenance"
                    control={<Radio />}
                    label="Maintenance Mode"
                    disabled
                  />
                  <InlineToolTip>
                    When a tank's status is set to 'Maintenance', data will be
                    collected however alarms will not sound as long as the tank
                    is set to Maintenance. Maintenance mode should be turned on
                    if a tank is being worked on, filled, or any other activity
                    is happening that could falsely trigger alarms. Please
                    contact Boreas Support to enable Maintenance Mode on this
                    tank
                  </InlineToolTip>
                </>
              )}
            </RadioGroup>
          </FormControl>
        </InputFieldsWrapper>
      </FormContainer>

      {showDeactivate && (
        <>
          {isEdit && tank.deleted && (
            <Button
              variant="outlined"
              color="error"
              onClick={handlers.reactivateTank}
              disabled={loading}
            >
              REACTIVATE TANK
            </Button>
          )}
          {isEdit && !tank.deleted && (
            <Button
              variant="outlined"
              color="error"
              onClick={handlers.deactivateTank}
              disabled={loading}
            >
              DEACTIVATE TANK
            </Button>
          )}
        </>
      )}

      {error && (
        <FormSubmitError>
          <ErrorIcon fontSize="small" />
          {error}
        </FormSubmitError>
      )}

      <ActionButtonsContainerWithSpace>
        <Button onClick={handlers.cancel}>Cancel</Button>
        {loading ? (
          <Button disabled variant="contained">
            Saving&#8230;
          </Button>
        ) : (
          <Button onClick={handlers.save} variant="contained">
            Save
          </Button>
        )}
      </ActionButtonsContainerWithSpace>
    </>
  );
};

export default OrganizationTankForm;
