// Boiler Plate Imports
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { useParams } from "react-router-dom";
import { formatDateTimeStamp } from "../../utils/date";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import TableHeader from "../../components/Page/tableHeader";
import { NoTableDataPlaceholder } from "../../components/NoDataPlaceholder";

// Page Component Imports
import MetricBoxes from "../../components/Page/metricBoxes";
import TableSubHeader from "../../components/Page/tableSubHeader";

import DeviceConnectivityTestModal from "./deviceConnectivityTestModal";
import { updateDeviceConnectivityTestModal } from "../../redux-store/uxSlice";
import DeviceQCStatusWidget from "../../components/Widgets/deviceQCStatusWidget";

import ActivityWidget from "../../components/Widgets/activityWidget";
import DetailsWidgetComponent from "../../components/Widgets/detailsWidget";

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const LeftColumn = styled.div`
  width: 100%;
  min-width: 0px;
`;
const RightColumn = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 0px;
  @media (max-width: 1080px) {
    max-width: 100%;
  }
`;

const LowerCaseButton = styled(Button)`
  text-transform: none !important;
`;

const AdminDeviceDashboardContainer = () => {
  const { deviceId } = useParams();

  const [device, setDevice] = useState({});
  const [deviceQC, setDeviceQC] = useState({});
  const [deviceMetrics, setDeviceMetrics] = useState([]);
  const [deviceCalibrations, setDeviceCalibrations] = useState([]);
  const [deviceConnectivityTests, setDeviceConnectivityTests] = useState([]);

  const [loadingDevice, setLoadingDevice] = useState(true);
  const [loadingDeviceQC, setLoadingDeviceQC] = useState(true);
  const [loadingDeviceCalibrations, setLoadingDeviceCalibrations] =
    useState(true);
  const [loadingDeviceConnectivityTests, setLoadingDeviceConnectivityTests] =
    useState(true);

  const dispatch = useDispatch();

  const calibrationTableColumns = [
    {
      title: "Start Date",
      propertyName: "startDateString",
      sortProperty: "startDate",
      sortPropertyType: "number",
      sortDefault: "desc",
    },
    {
      title: "Status",
      propertyName: "status",
      sortProperty: "status",
      sortPropertyType: "string",
    },
    {
      title: "Operator Name",
      propertyName: "userName",
      sortProperty: "userName",
      sortPropertyType: "string",
    },
    {
      title: "Firmware",
      propertyName: "fwVersion",
      sortProperty: "fwVersion",
      sortPropertyType: "number",
    },
    {
      title: "Weight Set",
      propertyName: "weightSerial",
      sortProperty: "weightSerial",
      sortPropertyType: "string",
    },
    {
      title: "Thermometer Set",
      propertyName: "thermometerSerial",
      sortProperty: "thermometerSerial",
      sortPropertyType: "string",
    },
    {
      title: "WP 0kg",
      propertyName: "calibrationWeightPlatform0kgValue",
      sortProperty: "calibrationWeightPlatform0kgValue",
      sortPropertyType: "number",
    },
    {
      title: "WP 60kg",
      propertyName: "calibrationWeightPlatform60kgValue",
      sortProperty: "calibrationWeightPlatform60kgValue",
      sortPropertyType: "number",
    },
    {
      title: <>Weight (kg) &#177;</>,
      propertyName: "weightThresholdPlusMinus",
      sortProperty: "weightThresholdPlusMinus",
      sortPropertyType: "number",
    },
    {
      title: "Verify 60kg",
      propertyName: "verify60kgPassed",
      sortProperty: "verify60kgPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verify60kgPassed !== undefined) {
          if (row.verify60kgPassed) {
            return `Passed ${row.verify60kgValue}`;
          } else {
            return `Failed ${row.verify60kgValue || "[unknown]"}`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Verify 30kg",
      propertyName: "verify30kgPassed",
      sortProperty: "verify30kgPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verify30kgPassed !== undefined) {
          if (row.verify30kgPassed) {
            return `Passed ${row.verify30kgValue}`;
          } else {
            return `Failed ${row.verify30kgValue || "[unknown]"}`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Verify 05kg",
      propertyName: "verify05kgPassed",
      sortProperty: "verify05kgPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verify05kgPassed !== undefined) {
          if (row.verify05kgPassed) {
            return `Passed ${row.verify05kgValue}`;
          } else {
            return `Failed ${row.verify05kgValue || "[unknown]"}`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Drift Test",
      propertyName: "verifyDriftTestPassed",
      sortProperty: "verifyDriftTestPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verifyDriftTestPassed !== undefined) {
          if (row.verifyDriftTestPassed) {
            return `Passed`;
          } else {
            return `Failed`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: <>Temp &#8451; &#177;</>,
      propertyName: "tempThresholdPlusMinus",
      sortProperty: "tempThresholdPlusMinus",
      sortPropertyType: "number",
    },
    {
      title: "Room Temp",
      propertyName: "verifyThermometerRoomTemp",
      sortProperty: "verifyThermometerRoomTemp",
      sortPropertyType: "number",
    },
    {
      title: "Verify Thermistor",
      propertyName: "verifyThermistorPassed",
      sortProperty: "verifyThermistorPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verifyThermistorPassed !== undefined) {
          if (row.verifyThermistorPassed) {
            return `Passed ${row.verifyThermistorValue}`;
          } else {
            return `Failed ${row.verifyThermistorValue || "[unknown]"}`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Low Weight Alarm",
      propertyName: "verifyLowWeightAlarmPassed",
      sortProperty: "verifyLowWeightAlarmPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verifyLowWeightAlarmPassed !== undefined) {
          if (row.verifyLowWeightAlarmPassed) {
            return `Passed`;
          } else {
            return `Failed`;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Battery Charges",
      propertyName: "verifyChargePassed",
      sortProperty: "verifyChargePassed",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.verifyChargePassed !== undefined) {
          if (row.verifyChargePassed) {
            return `Passed`;
          } else {
            return `Failed`;
          }
        } else {
          return "";
        }
      },
    },
  ];

  const connectivityTestsTableColumns = [
    {
      title: "Start Date",
      propertyName: "startDateString",
      sortProperty: "startDate",
      sortPropertyType: "number",
      sortDefault: "desc",
    },
    {
      title: "Status",
      propertyName: "status",
      sortProperty: "status",
      sortPropertyType: "string",
    },
    {
      title: "Operator Name",
      propertyName: "userName",
      sortProperty: "userName",
      sortPropertyType: "string",
    },
    {
      title: "Battery Held Charge",
      propertyName: "verifyBatteryHeldChargePassed",
      sortProperty: "verifyBatteryHeldChargePassed",
      sortPropertyType: "boolean",
      render: (row) => {
        return row?.verifyBatteryHeldChargePassed !== undefined
          ? row.verifyBatteryHeldChargePassed
            ? "Passed"
            : "Failed"
          : "";
      },
    },
    {
      title: "Tank Module Events Received",
      propertyName: "verifyTankDataEventsPassed",
      sortProperty: "verifyTankDataEventsPassed",
      sortPropertyType: "boolean",
      render: (row) => {
        return row?.verifyTankDataEventsPassed !== undefined
        ? row.verifyTankDataEventsPassed
          ? "Passed"
          : "Failed"
        : "";
      },
    },
  ];

  useEffect(() => {
    dataLayer.GetDevice();
    dataLayer.GetDeviceQC();
    dataLayer.GetDeviceCalibrations();
    dataLayer.GetDeviceConnectivityTests();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: `...`,
          link: `/admin/devices/`,
        },
      ])
    );
    setDeviceMetrics([
      {
        title: "Alarms",
        value: "",
        to: "/",
      },
      {
        title: "Organization",
        value: "",
        to: "/",
      },
      {
        title: "Location",
        value: "",
        to: "/",
      },
      {
        title: "Tank",
        value: "",
        to: "/",
      },
      {
        title: "Firmware",
        value: "",
      },
      // {
      //   title: "BATTERY LEVEL",
      //   value: "-",
      // },
    ]);
  }, []);

  useEffect(() => {
    if (device.name) {
      dispatch(
        setBreadCrumbs([
          {
            title: "Admin Devices",
            link: "/admin/devices",
          },
          {
            title: `${device.name}`,
            link: `/admin/devices/${deviceId}`,
          },
        ])
      );

      setDeviceMetrics([
        {
          title: "Alarms",
          value:
            device?.tank?.activeAlarmsCount !== undefined
              ? device?.tank?.activeAlarmsCount
              : "--",
          to: "/alarms",
          disabled: device?.tank?.id === undefined,
        },
        {
          title: "Organization",
          value: device?.organization?.name || "--",
          to: "/organization",
          fontSize: "20px",
          disabled: device?.organization?.name === undefined,
        },
        {
          title: "Location",
          value: device?.tank?.location?.name || "--",
          to: `/locations/${device?.tank?.location?.id}`,
          fontSize: "20px",
          disabled: device?.tank?.location?.name === undefined,
        },
        {
          title: "Tank",
          value: device?.tank?.name || "--",
          to: `/tanks/${device?.tank?.id}`,
          fontSize: "20px",
          disabled: device?.tank?.name === undefined,
        },
        {
          title: "Firmware",
          value: `v${device?.particleDevice?.firmware_version}`,
        },
        // {
        //   title: "BATTERY LEVEL",
        //   value: `v${device?.particleDevice?.firmware_version}`,
        // },
      ]);
    }
  }, [device]);

  const dataLayer = {
    GetDevice: async () => {
      const device = await API.Device.DeviceGet({
        deviceId,
        pullMetrics: true,
      });

      setDevice(device);

      setLoadingDevice(false);
    },
    GetDeviceConnectivityTests: async () => {
      const connectivityTests = await API.Device.DeviceGetConnectivityTests({
        deviceId,
      });

      connectivityTests.forEach((item) => {
        item.startDateString = formatDateTimeStamp(item.startDate);
        if (item.active === "false") {
          if (item.passed) {
            item.status = `Passed ${formatDateTimeStamp(item.finishDate)}`;
          } else {
            item.status = `Failed ${formatDateTimeStamp(item.finishDate)}`;
          }
        } else {
          item.status = `In Progress`;
        }
      });

      setDeviceConnectivityTests(connectivityTests);

      setLoadingDeviceConnectivityTests(false);
    },
    GetDeviceCalibrations: async () => {
      const calibrations = await API.Device.DeviceGetCalibrations({
        deviceId,
      });

      calibrations.forEach((item) => {
        item.startDateString = formatDateTimeStamp(item.startDate);
        item.finishDate = item.finishDate || 0;
        if (item.finishDate) {
          if (item.passed) {
            item.status = `Completed ${formatDateTimeStamp(item.finishDate)}`;
          } else if (item.failed) {
            item.status = `Failed ${formatDateTimeStamp(item.finishDate)}`;
          } else {
            item.status = `Unknown ${formatDateTimeStamp(item.finishDate)}`;
          }
        } else {
          item.status = `In Progress`;
        }
      });

      setDeviceCalibrations(calibrations);

      setLoadingDeviceCalibrations(false);
    },
    GetDeviceQC: async () => {
      const deviceQC = await API.Device.DeviceGetQualityCertificate({
        deviceId,
      });

      setDeviceQC(deviceQC);

      setLoadingDeviceQC(false);
    },
    DeleteDevice: async () => {
      const _res = await API.Device.DeviceDelete({ deviceId });
    },
  };

  const handlers = {
    // deleteDevice: async () => {
    //   await dataLayer.DeleteDevice();
    //   navigate("/admin/devices");
    // },
    calibrateDevice: async () => {
      window.open(`${window.location}/calibrate`, "_blank");
    },
    startConnectivityTest: async (e) => {
      dispatch(
        updateDeviceConnectivityTestModal({
          show: true,
          device,
        })
      );
    },
  };

  return (
    <>
      <BreadCrumbs />
      <MetricBoxes
        organizationId={device?.tank?.organizationId}
        title={device?.name || "BOR-TM-"}
        metrics={deviceMetrics}
      />
      <ColumnWrapper>
        <LeftColumn>
          <DetailsWidgetComponent
            loading={false}
            title={device?.name}
            subtitle="Device Details"
            details={[
              `Device Created: ${
                device?.created ? formatDateTimeStamp(device?.created) : "n/a"
              }`,
              `Device Created By: ${device?.userName}`,
              `Tank Install Date: ${
                device?.tank?.installDate
                  ? formatDateTimeStamp(device?.tank?.installDate)
                  : "--"
              }`,
              `Device Alarms: ${
                device?.tank?.activeAlarmsCount !== undefined
                  ? device?.tank?.activeAlarmsCount
                  : "--"
              }`,
              `Firmware Version: v${device?.particleDevice?.firmware_version}`,
              `Partilce OS Version: ${device?.particleDevice?.system_firmware_version}`,
              {
                render: (
                  <>
                    Partilce Device Id:{" "}
                    <a
                      target="_blank"
                      href={`https://console.particle.io/boreas-monitoring-bsom-${device?.product}/devices/${device?.particleDeviceId}`}
                      rel="noreferrer"
                    >
                      {device?.particleDeviceId}
                    </a>
                  </>
                ),
              },
            ]}
          />
          <PaddingWrapper>
            <Card>
              <TableHeader>
                <div>
                  <h2>Calibration History</h2>
                  <TableSubHeader>
                    View all calibrations for this device
                  </TableSubHeader>
                </div>

                <Button
                  onClick={handlers.calibrateDevice}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  CALIBRATE DEVICE
                </Button>
              </TableHeader>
              <Table
                columns={calibrationTableColumns}
                rows={deviceCalibrations}
                loading={false}
                searchEnabled={false}
                placeholder={
                  <NoTableDataPlaceholder
                    header="This device hasn't been calibrated yet"
                    body="To get started calibrate this device"
                    buttonText="Calibrate Device"
                    buttonPath="calibrate"
                    openInNewWindow={true}
                  />
                }
              />
            </Card>
          </PaddingWrapper>
          <PaddingWrapper>
            <Card>
              <TableHeader>
                <div>
                  <h2>Connectivity Tests History</h2>
                  <TableSubHeader>
                    View all connectivity tests for this device
                  </TableSubHeader>
                </div>

                <Button
                  onClick={handlers.startConnectivityTest}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  START CONNECTIVITY TEST
                </Button>
              </TableHeader>
              <Table
                columns={connectivityTestsTableColumns}
                rows={deviceConnectivityTests}
                loading={false}
                searchEnabled={false}
                placeholder={
                  <NoTableDataPlaceholder
                    header="No connectivity tests have been run on this device"
                    body="To get continue click start connectivity test"
                    buttonText="Start Connectivity Test"
                    buttonOnClick={handlers.startConnectivityTest}
                  />
                }
              />
            </Card>
          </PaddingWrapper>
        </LeftColumn>
        <RightColumn>
          <DeviceQCStatusWidget
            title="Device Quality Control Status"
            qcIsValid={deviceQC?.qcIsValid}
            failedQC={!deviceQC?.passed}
            ready={!loadingDeviceQC}
            latestCalibration={deviceQC?.latestDeviceCalibration}
            latestConnectivityTest={deviceQC?.latestDeviceConnectivityTest}
            startCalibrationOnClick={handlers.calibrateDevice}
            startConnectivityTestOnClick={handlers.startConnectivityTest}
          />

          <DetailsWidgetComponent
            loading={false}
            // title={device?.name}
            subtitle="Device QC Details"
            details={[
              `Calibration Status: ${
                deviceQC?.latestDeviceCalibration?.passed
                  ? "Complete"
                  : "Needs Successful Calibration"
              }`,
              `Last Calibration Date: ${
                deviceQC?.latestDeviceCalibration?.finishDate
                  ? formatDateTimeStamp(
                      deviceQC?.latestDeviceCalibration.finishDate
                    )
                  : "N/A"
              }`,
              // `Next Calibration Due: ${deviceQC?.latestDeviceCalibration  ? deviceQC?.latestDeviceCalibration.finishDate : "N/A"}`,
              `Connectivity Test Status: ${
                deviceQC?.latestDeviceConnectivityTest?.passed
                  ? "Complete"
                  : "Needs Successful Connectivity Test"
              }`,
              `Last Connectivity Test Date: ${
                deviceQC?.latestDeviceConnectivityTest?.finishDate
                  ? formatDateTimeStamp(
                      deviceQC?.latestDeviceConnectivityTest?.finishDate
                    )
                  : "N/A"
              }`,
            ]}
          />
          {/* <ActivityWidget
            header="Device Activity Log"
            resource="device"
            resourceId={device?.id}
            ready={true}
          /> */}
        </RightColumn>
      </ColumnWrapper>
      <DeviceConnectivityTestModal />
    </>
  );
};

export default AdminDeviceDashboardContainer;
